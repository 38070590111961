<!-- (c) 智汇考题 -->
<template>
    <div id="content" class="container">
        <nav-header :title="header_title"></nav-header>
        <div class="search">
            <van-search
                v-model="value"
                :show-action="showAction"
                input-align="left"
                :reverse-color="reverseColor"
                placeholder="请输入搜索关键词"
                @search="onSearch()"
                @cancel="onCancel()"
                @focus="onFocus()"
                @clear="onClear()">
                <div slot="action" @click="value ? onSearch() : onCancel()">
                    {{ value ? '搜索' : '' }}
                </div>
            </van-search>
        </div>
        <template v-if="list.length > 0">
            <div class="list">
                <div class="row" v-for="item in list" :key="item.id" @click="goPlay(item.id)">
                    <div class="question">
                        <div class="content-render" v-latex>
                            <strong class="type">[{{item.typeText}}] </strong> 
                        {{item.content_transform}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="drop">
                <span v-if="over">已经到底了</span>
            </div>
        </template>
        <template v-if="none">
            <div class="none">
                <none></none>
            </div>
        </template>
    </div>
</template>
<script>
import Vue from 'vue';
import { Search,Toast } from 'vant-green';

Vue.use(Search).use(Toast);
import NavHeader from "../../../components/nav-header.vue";
import None from "../../../components/none.vue";


export default{
    components: {
        NavHeader,
        None
    },
    data() {
        return {
            value: '',
            header_title: '搜索题目',
            showAction: false,
            reverseColor: true,
            list: [],
            pagination: {
                page: 1,
                size: 20,
                keyword: ''
            },
            over: false,
            loading: false,
            none: false,
            typeList: ['','单选题','多选题','填空题','问答题','判断题','题帽题'],
        }
    },
    beforeRouteLeave(to, from, next) {
        if (from.meta.keepAlive) {
            // 只针对 keep-alive 的页面记录滚动位置
            let $content = document.querySelector('#content'); // 列表的外层容器
            let scrollTop = $content ? $content.scrollTop : 0;
            from.meta.scrollTop = scrollTop;
        }
        next();
    },
    beforeRouteEnter(to, from, next) {
        if (from.name === 'AnswerSearchPlay') {
            //判断是从哪个路由过来的，若是detail页面不需要刷新获取新数据，直接用之前缓存的数据即可
            to.meta.useCache = true;
        }else {
            to.meta.useCache = false;
        }
        next()
    },
    activated() { 
        if(!this.$route.meta.useCache){
            window.addEventListener("scroll", this.ScrollToBottomEvt, true);
            this.list = [];
            this.value = '';
            this.none = false;
            this.over = false;
            this.pagination.keyword = this.value;
            this.pagination.page = 1;
        }else{
            console.log('此时我们读缓存')
            window.addEventListener("scroll", this.ScrollToBottomEvt, true);
        }
        let scrollTop = this.$route.meta.scrollTop;
        if (scrollTop && scrollTop !== 0) {
            // 初次进入页面不执行，返回页面执行
            let $content = document.querySelector('#content');
            if ($content) {
                this.$nextTick(() => {
                $content.scrollTop = scrollTop;
                })
            }
        }
    },
    mounted() {
        window.addEventListener("scroll", this.ScrollToBottomEvt, true);
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.ScrollToBottomEvt, true);
    },
    deactivated() {
        window.removeEventListener("scroll", this.ScrollToBottomEvt, true);
    },
    methods: {
        onSearch() {
            console.log(this.value)
            this.list = [];
            this.none = false;
            this.over = false;
            this.pagination.keyword = this.value;
            this.pagination.page = 1;
            this.getData();
        },
        onFocus() {
            this.showAction = true;
        },
        onCancel() {
            this.showAction = false;
        },
        onClear() {
            this.value = '';
        },
        getData(more = false) {
            console.log(1)
            if (this.over) {
                return;
            }
            console.log(11)
            if (this.loading) {
                return;
            }
            console.log(111)
            if (more) {
                this.pagination.page++;
            }
            console.log(1111)
            Toast.loading({
                type: 'loading',
                forbidClick: true
            });
            this.loading = true;
            this.$api.Exam.Search(this.pagination).then((res) => {
                let list = res.data.records;
                for (let i = 0; i < list.length; i++) {
                    list[i].typeText = this.typeList[list[i].type];
                    if (list[i].type == 6) {
                        let content = JSON.parse(list[i].content);
                        list[i].content_transform = content.replace('/<[^<]+>/', "");
                    }else  {
                        list[i].content_transform = list[i].content.replace('/<[^<]+>/', "");
                    }
                }
                if (list.length > 0) {
                    this.list.push(...list);
                }
                if (!this.list.length) {
                    this.none = true;
                }
                setTimeout(() => {
                    this.loading = false;
                    if (list.length < this.pagination.size) {
                        this.over = true;
                    }
                }, 200);
                Toast.clear();
            });
        },
        ScrollToBottomEvt() {
            const el = document.getElementById("content");
            const toBottom = el.scrollHeight - window.screen.height - el.scrollTop;
            if (toBottom < 10) {
                this.getData(true);
            }
        },
        goPlay(id) {
            this.$router.push({
                name: "AnswerSearchPlay",
                query: {
                    id: id,
                },
            });
        }
    }
}
</script>
<style lang="less" scoped>
.search{
    margin-top: 50px;
}
.list{
    margin: 30rpx 0;
}
.row{
    border-bottom: 1px solid #ececec;
    padding: 15px;
}
.question{
    padding: 30rpx;
}
.type{
    color: #46C6BA;
}
.none {
    margin-top: 80px;
}
.drop {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #cccccc;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.content-render {
    float: none;
}
</style>